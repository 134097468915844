<template>
  <v-theme-provider dark>
    <section id="hero-field">
      <v-img
        :height="$vuetify.breakpoint.mdAndUp ? 600 : 400"
        :gradient="gradient"
        :lazy-src="lazySrc"
        :src="src"
        color="#45516b"
        flat
        max-width="100%"
        tile
      >
        <v-container class="fill-height px-4 py-12">
          <v-responsive
            class="d-flex align-center"
            height="100%"
            width="100%"
            align="center"
          >
            <base-img
              :src="require('@/assets/logo_only_circle.png')"
              class="mr-3 hidden-xs-only"
              contain
              max-width="8%"
              min-height="8%"
              width="100%"
            />
            <base-heading :title="$t(`HeroField.${this.$route.meta.option}`)" />
            <base-body>
              <v-row
                class="mb-6"
                justify="center"
                align="center"
                no-gutters
              >
                {{ $t(`HeroField.shortInfo.${this.$route.meta.option}`) }}
              </v-row>
            </base-body>
            <base-btn
              large
              target="_blank"
              @click="changePage()"
            >
              {{ $t('HeroField.btnText') }}
            </base-btn>
          </v-responsive>
        </v-container>
      </v-img>
    </section>
  </v-theme-provider>
</template>

<script>
  // Components
  import {
    HexToRGBA,
    RGBAtoCSS,
  } from 'vuetify/lib/util/colorUtils'

  export default {
    name: 'SectionHeroField',
    provide: {
      heading: { align: 'center' },
    },
    computed: {
      gradient () {
        const color = `${this.$vuetify.theme.themes.light.secondary}CC`
        const overlay = RGBAtoCSS(HexToRGBA(color))
        return `to top, ${overlay}, ${overlay}`
      },
      src () {
        return this.$route.meta.src
      },
      lazySrc () {
        return this.$route.meta.lazySrc
      },
      items () {
        return [
          { text: 'HOME', to: '/' },
          { text: this.title },
        ]
      },
    },
    methods: {
      changePage () {
        return this.$router.replace({ name: 'Contact' })
      },
    },
  }
</script>
